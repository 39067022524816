import { CoreMenu } from '@core/types'

//AOS DEVS: LEMBREM-SE SEMPRE DE MANTER O MENU EM ORDEM ALFABÉTICA!!!!!!!!!!!!!!!
export const menu: CoreMenu[] = [
  {
    id: 'menu-dashboard',
    title: 'Home',
    type: 'item',
    icon: 'home',
    url: '/home'
  },
  {
    id: 'menu-administrativo',
    title: 'Administrativo',
    type: 'collapsible',
    icon: 'edit',
    children: [{
      id: 'menu-inventario',
      title: 'Inventário',
      type: 'item',
      icon: '',
      url: '/inventario',
    }
    ]
  },
  {
    id: 'menu-cadastro',
    title: 'Cadastro',
    type: 'collapsible',
    icon: 'file-text',
    children: [
      // {
      //   id: 'menu-abastecimento',
      //   title: 'Abastecimento',
      //   type: 'item',
      //   icon: '',
      //   url: '/abastecimento',
      // },
      {
        id: 'menu-bancos',
        title: 'Bancos',
        type: 'item',
        icon: '',
        url: '/banco',
      },
      // {
      //   id: 'menu-bombas',
      //   title: 'Bombas',
      //   type: 'item',
      //   icon: '',
      //   url: '/bombas',
      // },
      {
        id: 'menu-cidade',
        title: 'Cidade',
        type: 'item',
        icon: '',
        url: '/cidade',
      },
      // {
      //   id: 'menu-contaContabil',
      //   title: 'Conta Contábil',
      //   type: 'item',
      //   icon: '',
      //   url: '/contaContabil',
      // },
      {
        id: 'menu-CustoItemFinanceiro',
        title: 'Custos Financeiros',
        type: 'item',
        icon: '',
        url: '/custoFinanceiro',
      },
      {
        id: 'menu-embalagens',
        title: 'Embalagens',
        type: 'item',
        icon: '',
        url: '/embalagem',
      },
      {
        id: 'menu-empresa',
        title: 'Empresas',
        type: 'item',
        icon: '',
        url: '/empresa',
      },
      {
        id: 'menu-estado',
        title: 'Estado',
        type: 'item',
        icon: '',
        url: '/estado',
      },
      {
        id: 'menu-familiaParceiro',
        title: 'Família',
        type: 'item',
        icon: '',
        url: '/familia',
      },
      {
        id: 'menu-frases-padrao',
        title: 'Frase padrão',
        type: 'item',
        icon: '',
        url: '/frase-padrao',
      },
      // {
      //   id: 'menu-formaPagamento',
      //   title: 'Forma Pagamento',
      //   type: 'item',
      //   icon: '',
      //   url: '/formaPagamento',
      // },
      {
        id: 'menu-funcaoParceiroNegocio',
        title: 'Função Parceiros Negócio',
        type: 'item',
        icon: '',
        url: '/funcaoParceiro',
      },
      {
        id: 'menu-incoterm',
        title: 'Incoterms',
        type: 'item',
        icon: '',
        url: '/incoterm',
      },
      {
        id: 'menu-origem-destino',
        title: 'Origem/Destino',
        type: 'item',
        icon: '',
        url: '/origemdestino',
      },
      // {
      //   id: 'menu-manutencao',
      //   title: 'Manutenção',
      //   type: 'item',
      //   icon: '',
      //   url: '/manutencao',
      // },
      {
        id: 'menu-mercadorias',
        title: 'Mercadorias',
        type: 'item',
        icon: '',
        url: '/mercadoria',
      },
      {
        id: 'menu-moedas',
        title: 'Moedas',
        type: 'item',
        icon: '',
        url: '/moeda',
      },
      {
        id: 'menu-navio',
        title: 'Navio',
        type: 'item',
        icon: '',
        url: '/navio',
      },
      // {
      //   id: 'menu-ncm',
      //   title: 'Ncm',
      //   type: 'item',
      //   icon: '',
      //   url: '/ncm',
      // },
      {
        id: 'menu-pais',
        title: 'País',
        type: 'item',
        icon: '',
        url: '/pais',
      },
      {
        id: 'menu-parceiroNegocio',
        title: 'Parceiros de Negócio',
        type: 'item',
        icon: '',
        url: '/parceiro/parceiro-negocio',
      },
      {
        id: 'menu-recinto-alfandegado',
        title: 'Recintos Alfandegados',
        type: 'item',
        icon: '',
        url: '/recinto-alfandegado',
      },
      {
        id: 'menu-regraCalculo',
        title: 'Regras de Cálculo',
        type: 'item',
        icon: '',
        url: '/regraCalculo',
      },
      {
        id: 'menu-arquivo-tipo-documento',
        title: 'Tipo Documento (Arquivo)',
        type: 'item',
        icon: '',
        url: '/arquivo-tipo-documento',
      },
      {
        id: 'menu-tipo-container',
        title: 'Tipo Container',
        type: 'item',
        icon: '',
        url: '/tipocontainer',
      },
      {
        id: 'menu-unidade-receita-federal',
        title: 'Unidades Receita Federal',
        type: 'item',
        icon: '',
        url: '/unidade-receita-federal',
      }//,
      // {
      //   id: 'menu-veiculos',
      //   title: 'Veículos',
      //   type: 'item',
      //   icon: '',
      //   url: '/veiculos',
      // }
    ]
  },
  {
    id: 'menu-comercial',
    title: 'Comercial',
    type: 'collapsible',
    icon: 'trending-up',
    children: [
      {
        id: 'menu-crm',
        title: 'CRM',
        type: 'item',
        icon: '',
        url: '/parceiro/crm'
      },
      {
        id: 'menu-oportunidade-oferta',
        title: 'Oportunidade',
        type: 'item',
        icon: '',
        url: '/oportunidadeOferta',
      },
      {
        id: 'menu-farol-metas',
        title: 'Farol de Metas',
        type: 'item',
        icon: '',
        url: '/farolMetas'
      },
      // {
      //   id: 'menu-oportunidade',
      //   title: 'Oportunidade',
      //   type: 'item',
      //   icon: '',
      //   url: '/oportunidade',
      // }
    ]
  },
  {
    id: 'menu-configuracoes',
    title: 'Configurações',
    type: 'collapsible',
    icon: 'settings',
    children: [
      {
        id: 'menu-agenda-automatica',
        title: 'Agenda Automática',
        type: 'item',
        icon: '',
        url: '/agenda-automatica',
      },
      {
        id: 'menu-cargos',
        title: 'Cargos',
        type: 'item',
        icon: '',
        url: '/cargo',
      },
      {
        id: 'menu-departamentos',
        title: 'Departamentos',
        type: 'item',
        icon: '',
        url: '/departamento',
      },
      {
        id: 'menu-filiais',
        title: 'Filiais',
        type: 'item',
        icon: '',
        url: '/filial',
      },
      {
        id: 'menu-grupos',
        title: 'Grupos',
        type: 'item',
        icon: '',
        url: '/grupo',
      },
      {
        id: 'menu-metas',
        title: 'Metas',
        type: 'item',
        icon: '',
        url: '/meta',
      },
      {
        id: 'menu-modelo-email',
        title: 'Modelos de E-mail',
        type: 'item',
        icon: '',
        url: '/modelo-email',
      },
      {
        id: 'menu-query-dinamica',
        title: 'Query Dinâmica',
        type: 'item',
        icon: '',
        url: '/query-dinamica',
      },
      {
        id: 'menu-parametros',
        title: 'Parâmetros',
        type: 'item',
        icon: '',
        url: '/parametro',
      },
      {
        id: 'menu-roles',
        title: 'Perfis',
        type: 'item',
        icon: '',
        url: '/role',
      },
      {
        id: 'menu-permissoes',
        title: 'Permissões',
        type: 'item',
        icon: '',
        url: '/permissao',
      },
      {
        id: 'menu-recursos',
        title: 'Recursos',
        type: 'item',
        icon: '',
        url: '/recurso',
      },
      {
        id: 'menu-usuario',
        title: 'Usuários',
        type: 'item',
        icon: '',
        url: '/usuario',
      }]
  },
  {
    id: 'menu-Financeiro',
    title: 'Financeiro',
    type: 'collapsible',
    icon: 'dollar-sign',
    children: [
      //  { id: 'menu-DemurrageDetention',
      //   title: 'Demurrage/ Detention',
      //   type: 'item',
      //   icon: '',
      //   url: '/DemurrageDetention',
      // },      
      {
        id: 'menu-budget',
        title: 'Budget',
        type: 'item',
        icon: '',
        url: '/budget',
      },
      {
        id: 'menu-categoria-financeira',
        title: 'Categoria Financeira',
        type: 'item',
        icon: '',
        url: '/categoria-financeira',
      },
      {
        id: 'menu-centro-custo',
        title: 'Centro de Custo',
        type: 'item',
        icon: '',
        url: '/centro-custo',
      },
      {
        id: 'menu-conta-bancaria',
        title: 'Conta Bancária',
        type: 'item',
        icon: '',
        url: '/conta-bancaria',
      },
      {
        id: 'menu-conta-contabil',
        title: 'Conta Contábil',
        type: 'item',
        icon: '',
        url: '/conta-contabil',
      },
      {
        id: 'menu-faturas',
        title: 'Despesa',
        type: 'item',
        icon: '',
        url: '/despesa',
      },
      {
        id: 'menu-extrato-bancario',
        title: 'Extrato Bancário',
        type: 'item',
        icon: '',
        url: '/extrato-bancario',
      },
      {
        id: 'menu-faturas',
        title: 'Faturas',
        type: 'item',
        icon: '',
        url: '/fatura',
      }
      // },
      // {
      //   id: 'menu-notaFiscal',
      //   title: 'Nota Fiscal',
      //   type: 'item',
      //   icon: '',
      //   url: '/notafiscal',

      // {
      //   id: 'menu-notaFiscal',
      //   title: 'Nota Fiscal',
      //   type: 'item',
      //   icon: '',
      //   url: '/notafiscal',},
    ]
  },
  // {
  //   id: 'menu-Operacional',
  //   title: 'Operacional',
  //   type: 'collapsible',
  //   icon: 'package',
  //   children: [
  //     {
  //       id: 'menu-processo',
  //       title: 'Processo',
  //       type: 'item',
  //       icon: '',
  //       url: '/Processo',
  //     }]
  // },
  {
    id: 'menu-pricing',
    title: 'Pricing',
    type: 'collapsible',
    icon: 'shopping-bag',
    children: [
      {
        id: 'menu-tarifario',
        title: 'Tarifário',
        type: 'item',
        icon: '',
        url: '/tarifario',
      },
      {
        id: 'menu-tarifario-local',
        title: 'Tarifário Local',
        type: 'item',
        icon: '',
        url: '/tarifariolocal',
      },
      {
        id: 'menu-tarifario-demurrage',
        title: 'Tarifário Demurrage',
        type: 'item',
        icon: '',
        url: '/tarifario-demurrage',
      }
    ]
  },
  {
    id: 'menu-processos',
    title: 'Processo',
    type: 'collapsible',
    icon: 'trending-up',
    children: [
      {
        id: 'menu-processo',
        title: 'Processo',
        type: 'item',
        icon: '',
        url: '/processo',
      },
      {
        id: 'menu-controle-booking',
        title: 'Controle Boooking',
        type: 'item',
        icon: '',
        url: '/controle-booking',
      },
      {
        id: 'menu-devolucao-container',
        title: 'Devolução de Container',
        type: 'item',
        icon: '',
        url: '/devolucao-container',
      }
    ]
  },
  {
    id: 'menu-relatorio',
    title: 'Relatórios',
    type: 'item',
    icon: 'bar-chart-2',
    url: '/relatorios'
  },
]
